<template>
  <ion-page>
    <ion-content :fullscreen="true" class="app no-scroll">
      <div class="swipeListener" ref="rectangle" @click="newopencheck()"></div>
      <div class="logo">
        <span class="logo__s">S</span>lam<span class="logo__j">J</span>am<span
          class="logo__p"
          >P</span
        >oem
      </div>

      <Poem
        :selected="selected"
        :z-index="state"
        :fadeout="fadeout ? 'shown' : 'fadeout'"
      />
      <Poem
        :selected="nextpoem"
        :z-index="state2"
        :fadeout="fadeout ? 'fadeout' : 'shown'"
      />

      <div class="buttons">
        <div class="swipe__info">
          &#x3c; swipe for new Poem &#x3e;
        </div>
        <!-- <ion-button expand="block" @click="getNext()">Next</ion-button><br /> -->
        <ion-button expand="block" @click="addPoem()">Submit Poem</ion-button>
      </div>
      <div class="new__poem" :class="{ 'new__poem--show': newPoem }">
        <!-- Textarea in an item with a placeholder -->
        <ion-input
          v-model="poem.title"
          spellcheck="true"
          inputmode="text"
          autocapitalize="."
          placeholder="Enter Title"
          :required="true"
        ></ion-input>
        <ion-textarea
          v-model="poem.body"
          spellcheck="true"
          inputmode="text"
          placeholder="Enter Poem"
          autocapitalize="."
          :required="true"
        ></ion-textarea>
        <ion-input v-model="poem.author" placeholder="Enter Author"></ion-input>
        <ion-button expand="block" @click="submit()">Submit</ion-button>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonPage,
  IonTextarea,
  IonButton,
  IonInput,
  createGesture,
} from "@ionic/vue";

import Poem from "@/components/Poem.vue";

import { defineComponent } from "vue";

export default defineComponent({
  name: "Home",
  components: {
    IonContent,
    IonPage,
    IonTextarea,
    IonButton,
    IonInput,
    Poem,
  },
  data() {
    return {
      poems: [],
      poem: {
        title: "",
        body: "",
        author: "",
        category: "normal",
      },
      selected: {},
      nextpoem: {},
      images: [],
      newPoem: false,
      poemsCount: 0,
      paging: 0,
      loading: true,
      state: 0,
      state2: 1,
      fadeout: true,
      activeId: null,
    };
  },
  mounted() {
    this.getPoems("init");
    const onMove = (detail) => {
      if (detail.type == "pan" && !this.loading) this.getNext();
    };
    const gesture = createGesture({
      el: document.body,
      onEnd: (detail) => {
        onMove(detail);
      },
    });
    gesture.enable();
    document.addEventListener("ionBackButton", (ev) => {
      ev.detail.register(10, () => {
        if (this.newPoem) {
          this.newPoem = false;
        }
      });
    });
  },
  methods: {
    newopencheck() {
      if (this.newPoem) {
        this.newPoem = false;
      }
    },
    async getData(url = "") {
      const myHeaders = new Headers();
      myHeaders.append("APIKEY", "SJP_VUE_APP");
      const response = await fetch(url, {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      })
        .then(async (response) => {
          if (response.ok) {
            this.loading = false;
            return await response.json();
          }
        })
        .catch((e) => {
          console.log(e);
        });

      return response; // parses JSON response into native JavaScript objects
    },
    async postData(url = "", data = {}) {
      const response = await fetch(url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          //"Content-Type": "application/json",
          APIKEY: "SJP_VUE_APP",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      });
      return response.json(); // parses JSON response into native JavaScript objects
    },
    submit() {
      this.newPoem = false;
      this.postData("https://slamjampoem.de/api/post", this.poem).then(() => {
        this.poem = { title: "", body: "", author: "", category: "normal" };
      });
      //http://localhost:3000/
      // this.poems.push(this.poem);
    },
    random(state) {
      if (this.poems.length == 0) {
        if (this.paging == 0) {
          this.getPoems();
        } else {
          this.getNextPoems();
        }
        return false;
      }
      const selectPoem = 0;
      let selectPoem2 = 0;

      const initpoem = this.poems[selectPoem];
      // console.log("poems before", this.poems);
      if (state == "init") {
        this.poems = this.arrayRemove(this.poems, initpoem);
        selectPoem2 = 1;
      }
      let image = "https://source.unsplash.com/1600x900/?nature";
      let image2 = "https://source.unsplash.com/1600x900/?nature";

      if (this.images) {
        if (state == "init") {
          image = this.images[selectPoem].urls.regular;
          this.images = this.arrayRemove(this.images, this.images[selectPoem]);
          image2 = this.images[selectPoem2].urls.regular;
          this.images = this.arrayRemove(this.images, this.images[selectPoem2]);
        } else if (!this.fadeout) {
          image2 = this.images[selectPoem].urls.regular;
          this.images = this.arrayRemove(this.images, this.images[selectPoem]);
        } else {
          image2 = this.images[selectPoem].urls.regular;
          this.images = this.arrayRemove(this.images, this.images[selectPoem]);
        }
      }
      if (state !== "init") {
        if (this.fadeout) {
          this.fadeout = false;
        } else {
          this.fadeout = true;
        }
      }

      setTimeout(() => {
        if (state == "init") {
          this.selected = {
            poem: initpoem,
            image: image,
          };
          this.nextpoem = {
            poem: this.poems[selectPoem2],
            image: image2,
          };
        } else if (!this.fadeout) {
          this.selected = {
            poem: this.poems[selectPoem2],
            image: image2,
          };
        } else {
          this.nextpoem = {
            poem: this.poems[selectPoem2],
            image: image2,
          };
        }

        this.poems = this.arrayRemove(this.poems, this.poems[selectPoem2]);
        // console.log("poems after", this.poems);
        this.loading = false;
      }, 400);
    },
    getRandomInt(max) {
      return Math.floor(Math.random() * max);
    },
    shuffle(array) {
      let currentIndex = array.length,
        randomIndex;

      // While there remain elements to shuffle...
      while (currentIndex != 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex],
          array[currentIndex],
        ];
      }

      return array;
    },
    arrayRemove(arr, value) {
      return arr.filter(function(ele) {
        return ele != value;
      });
    },
    getPoems(state) {
      this.getData("https://slamjampoem.de/api/posts").then((data) => {
        // console.log("DATA", data); // JSON data parsed by `data.json()` call
        this.poems = this.shuffle(data.poems);
        this.images = data.images;
        this.poemsCount = data.poems.length;
        this.paging = data.paging;
        this.random(state);
      });
    },
    getNextPoems() {
      this.getData("https://slamjampoem.de/api/posts/" + this.poemsCount).then(
        (data) => {
          // console.log("DATA", data); // JSON data parsed by `data.json()` call
          this.poems = data.poems;
          this.images = data.images;
          this.paging = data.paging;
          if (data.paging == 0) {
            this.poemsCount = data.poems.length;
          } else {
            this.poemsCount += data.poems.length;
          }
          this.random();
        }
      );
    },
    getNext() {
      this.loading = true;
      this.random();
    },
    addPoem() {
      this.newPoem = true;
    },
  },
});
</script>

<style>
.poem {
  transition: all 0.5s ease;
}
.shown {
  opacity: 1;
}
.fadeout {
  opacity: 0;
}
.rectangle {
  width: 500px;
  height: 100px;
  background: rgba(0, 0, 255, 0.5);
  z-index: 5000;
  position: absolute;
}

.scroll-y {
  overflow-y: hidden !important;
}
.swipeListener {
  position: absolute;
  z-index: 9;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.buttons {
  position: absolute;
  z-index: 20;
  bottom: 0;
  width: 100%;
  padding: 16px;
  max-width: 800px;
  left: 50%;
  transform: translateX(-50%);
}
.app {
  background: #000;
  overflow-y: hidden;
  --overflow: hidden;
}
.logo {
  font-family: "Caveat";
  position: absolute;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100%;
  text-shadow: -1px -1px 0 #00000030, 1px -1px 0 #00000030, -1px 1px 0 #00000030,
    1px 1px 0 #00000030;
  text-align: center;
  font-size: 40px;
  padding-top: 16px;
  user-select: none;
}
.swipe__info {
  font-family: "Caveat";
  text-align: center;
  font-size: 18px;
  margin-bottom: 16px;
  user-select: none;
}

.native-textarea.sc-ion-textarea-md {
  min-height: 200px;
}
.logo span {
  font-size: 50px;
}
.logo__s {
  margin-right: 0px;
}
.logo__j {
  margin-right: -2px;
  margin-left: -2px;
}
.logo__p {
  margin-left: 3px;
  margin-right: -6px;
}

.new__poem {
  position: absolute;
  z-index: 50;
  bottom: -100%;
  transition: all 0.5s ease;
  width: 100%;
  background: #000;
  padding: 32px;
  max-width: 800px;
  left: 50%;
  transform: translateX(-50%);
}
.new__poem--show {
  bottom: 0;
}
input,
textarea {
  border: 1px solid #fff;
}
#container {
  text-align: center;

  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;

  color: #8c8c8c;

  margin: 0;
}

#container a {
  text-decoration: none;
}
</style>
