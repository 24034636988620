<template>
  <ion-content class="ion-padding">
    <div class="settings">
      <p>Language:</p>

      <ion-button
        v-for="lang in config.lang"
        :color="selectedlang == lang.id ? 'primary' : 'light'"
        :key="lang.id"
        @click="setId(lang.id)"
      >
        {{ lang.longname }}
      </ion-button>
      <div class="mt32">
        <ion-button @click="closeModal">Close</ion-button>
      </div>
    </div>
  </ion-content>
</template>

<script>
import { IonContent, IonButton, modalController } from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Modal",
  components: { IonContent, IonButton },
  props: {
    selectedlang: String,
    config: Object,
  },
  setup() {
    const closeModal = () => {
      modalController.dismiss();
    };
    return { closeModal };
  },
  methods: {
    setId(id) {
      localStorage.setItem("lang", id);
      location.reload();
    },
  },
});
</script>

<style lang="css" scoped>
.settings {
  padding: 16px;
}
.mt32 {
  margin-top: 32px;
}
</style>
