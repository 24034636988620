<template>
  <div class="poem" :class="fadeout">
    <div class="blackwrapper" :class="zIndex == 0 ? 'deep' : 'high'">
      <div
        class="background__container"
        :style="{ backgroundImage: 'url(' + selected.image + ')' }"
        v-if="selected.image"
      ></div>
    </div>
    <div
      class="poem__container"
      v-if="selected.poem"
      :class="zIndex == 0 ? 'deep' : 'high'"
    >
      <h2 class="h2">{{ selected.poem.title }}</h2>
      <p class="bodytext" v-html="selected.poem.body"></p>
      <div class="author">
        {{ selected.poem.author }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Poem",
  props: {
    selected: {
      required: true,
    },
    zIndex: {
      required: true,
    },
    fadeout: {
      required: true,
    },
  },
});
</script>

<style scoped>
.h2 {
  font-size: 24px;
}
.bodytext {
  font-size: 16px;
}
.author {
  text-align: right;
  font-size: 12px;
  font-family: "Andada Pro";
  font-style: italic;
}
.background__container {
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  width: 100%;
  height: 100%;
  transform: translateY(-50%);
  opacity: 0.4;
}
.blackwrapper {
  z-index: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  width: 100%;
  height: 100%;
  transform: translateY(-50%);
  background-color: #000;
}
.high {
  z-index: 11;
}
.poem__container {
  font-family: "Andada Pro";
  z-index: 10;
  position: absolute;
  width: 100%;
  height: calc(100% - 100px - 150px);
  top: 100px;
  padding: 0 32px;
  font-size: 26px;
  text-align: center;
  max-width: 800px;
  left: 50%;
  transform: translateX(-50%);
  overflow-y: auto !important;
  user-select: none;
}
.poem__container.high {
  z-index: 12;
}
</style>
