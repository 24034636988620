import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "@ionic/vue-router";

import { RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import Nettiquette from "../views/Nettiquette.vue";
import Imprint from "../views/Imprint.vue";
import Dataprotection from "../views/Dataprotection.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/nettiquette",
    name: "Nettiquette",
    component: Nettiquette,
  },
  {
    path: "/imprint",
    name: "Imprint",
    component: Imprint,
  },
  {
    path: "/data-protection",
    name: "dataprotection",
    component: Dataprotection,
  },
];

const router = createRouter({
  history: process.env.IS_ELECTRON
    ? createWebHashHistory()
    : createWebHistory(),
  routes,
});

export default router;
